<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiCalendarArrowLeft }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-layout>
        <v-spacer></v-spacer>
        <div v-for="(permission, index) in permission" :key="index">
          <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
            <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
              <v-btn
                color="accent"
                v-show="user_submenu.create == 1 && permission.role_id == 3"
                rounded
                @click.prevent="$router.push({ name: 'addnewappointment' })"
              >
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>New Appointment</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <v-btn
            color="accent"
            v-show="currentUser.role_id == 1"
            rounded
            @click.prevent="$router.push({ name: 'addnewappointment' })"
          >
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>New Appointment</span>
          </v-btn>
        </div>
      </v-layout>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs>
      <v-tab>
        <v-icon left>{{ icons.mdiBookClock }} </v-icon>
        Upcomming Appointments
      </v-tab>
      <v-tab>
        <v-icon left> {{ icons.mdiCalendarArrowRight }} </v-icon>
        Previous Appointments
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-timeline name="upcoming_appointments">
              <v-timeline-item
                v-for="(item, i) in upcomingappointments"
                :key="i"
                :color="item.background_color"
                small
                fill-dot
              >
                <v-card>
                  <v-card-title :class="['text-h6', `bg-${item.background_color}`]">
                    {{ item.event_name }}
                  </v-card-title>
                  <v-card-text class="bg-white text--primary">
                    <p class="mt-3">
                      <v-icon class="mr-1" color="secondary">{{ icons.mdiAlarm }}</v-icon
                      >{{ formatDate(item.app_startdate) + ' ' + formatDate(item.app_enddate) }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiAccountTie }}</v-icon
                      >{{ item.customer_name }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiCar }}</v-icon
                      >{{ item.vehicle_name }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiCardAccountDetails }}</v-icon
                      >{{ item.estimate_number }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiEmailCheckOutline }}</v-icon
                      >{{ item.email_to_confirm }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-timeline name="prev_appointments">
              <v-timeline-item
                v-for="(item, i) in previousappointments"
                :key="i"
                :color="item.background_color"
                small
                fill-dot
              >
                <v-card>
                  <v-card-title :class="['text-h6', `bg-${item.background_color}`]">
                    {{ item.event_name }}
                  </v-card-title>
                  <v-card-text class="bg-white text--primary">
                    <p class="mt-3">
                      <v-icon class="mr-1" color="secondary">{{ icons.mdiAlarm }}</v-icon
                      >{{ formatDate(item.app_startdate) + ' ' + formatDate(item.app_datedate) }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiAccountTie }}</v-icon
                      >{{ item.customer_name }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiCar }}</v-icon
                      >{{ item.vehicle_name }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiCardAccountDetails }}</v-icon
                      >{{ item.estimate_number }}
                    </p>
                    <p>
                      <v-icon color="secondary" class="mr-1">{{ icons.mdiEmailCheckOutline }}</v-icon
                      >{{ item.email_to_confirm }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
import {
  mdiMenuDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiCalendarCheck,
  mdiPlus,
  mdiPencil,
  mdiDotsVertical,
  mdiAlarm,
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiCar,
  mdiBookClock,
  mdiCalendarArrowRight,
  mdiEmailCheckOutline,
} from '@mdi/js'
export default {
  name: 'Appointment',
  components: {},

  created: function () {
    this.getUpcomingAppointments()
    this.getPreviousAppointments()
    this.getAuthorizations()
  },
  data() {
    return {
      appointment: {},
      permission: {},
      upcomingappointment: '',
      previousappointment: '',
      upcomingappointments: [],
      previousappointments: [],

      icons: {
        mdiMenuDown,
        mdiChevronRight,
        mdiChevronLeft,
        mdiCalendarCheck,
        mdiPlus,
        mdiPencil,
        mdiDotsVertical,
        mdiAlarm,
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiCar,
        mdiBookClock,
        mdiCalendarArrowRight,
        mdiEmailCheckOutline,
      },
    }
  },
  methods: {
    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 2,
          submenu_id: 2,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
    getUpcomingAppointments: function () {
      this.$store
        .dispatch(`appointment/upcomingAppointments`)
        .then(response => {
          this.upcomingappointments = this.fetchUpcomingAppointments
        })
        .catch(err => console.log(err))
    },

    getPreviousAppointments: function () {
      this.$store
        .dispatch(`appointment/previousAppointments`)
        .then(response => {
          this.previousappointments = this.fetchPreviousAppointments
        })
        .catch(err => console.log(err))
    },
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
  computed: {
    ...mapGetters({
      Events: 'appointment/fetchAppointments',
      fetchUpcomingAppointments: 'appointment/fetchUpcomingAppointments',
      fetchPreviousAppointments: 'appointment/fetchPreviousAppointments',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style>
</style>